import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import Dependencies from './utils/Dependencies'

/* We import element-ui variables at the end so they can override the default element-ui colors */
import './assets/sass/element_variables.scss'

// Import the plugin here
import { Auth0Plugin } from './auth'

Vue.use(Dependencies)

// Configure development environment
if (process.env.NODE_ENV === 'development') {
  // Load fontawesome locally
  require('@fortawesome/fontawesome-free/css/all.css')
}

Vue.config.devtools = (process.env.NODE_ENV === 'development' || process.env.VUE_APP_ENV === 'STAGE')

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  authorizationParams: { audience: process.env.VUE_APP_AUTH0_AUDIENCE },
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

// Not sure what this was for. Its in the Auth0 docs so Ive included it.
Vue.config.productionTip = false

/* eslint-disable no-new */
window.app = new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
})
