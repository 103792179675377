import "core-js/modules/es.array.concat.js";
import { repositoryClass } from './RepositoryClass';

function repo() {
  return repositoryClass('auth', '');
}

export default {
  csrf: function csrf() {
    return repo().get('csrf', 'sanctum/csrf-cookie');
  },
  getUser: function getUser() {
    var userID = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var withMember = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return repo().get('getUser', 'get-user', {
      user_id: userID,
      with_member: withMember
    }, true);
  },
  getUserById: function getUserById(userID) {
    return repo().get('getUserById', "get-user/".concat(userID));
  },
  getScope: function getScope(facilityID) {
    var userID = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return repo().get('getScope', "".concat(facilityID, "/get-scope"), {
      user_id: userID
    });
  },
  getActivity: function getActivity(userID, page) {
    // Establish page number
    var param = page !== 1 ? "?page=".concat(page) : '';
    return repo().get('getActivity', "activity/".concat(userID).concat(param));
  },
  login: function login(payload) {
    return repo().post(payload, 'login', 'login');
  },
  logout: function logout() {
    return repo().post(null, 'logout', 'logout');
  },
  register: function register(payload) {
    return repo().post(payload, 'register', 'register');
  },
  requestResetCode: function requestResetCode(email) {
    return repo().post({
      email: email
    }, 'requestResetCode', 'request-code');
  },
  resetPassword: function resetPassword(payload) {
    return repo().post(payload, 'resetPassword', 'reset-password');
  },
  lookupBUE: function lookupBUE(payload) {
    return repo().post(payload, 'lookupBUE', 'lookup_bue');
  }
};